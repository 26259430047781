import React from 'react'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import { App } from 'App'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import { api } from 'api'
import { Content } from 'types'

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<Content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <ApiProvider api={api}>
          <App />
        </ApiProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
