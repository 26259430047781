import { Card } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import { useGetMatchingJobsQuery } from 'api'
import { Matches, NoMatches } from 'features'
import React from 'react'

export const App = () => {
  const user = useUser()
  const klientProperties = user?.klientProperties
  const matchingConsent = klientProperties?.consent.matching ?? false
  const {
    data: matches,
    isLoading: matchesLoading,
    error: matchError,
  } = useGetMatchingJobsQuery(undefined, { skip: matchingConsent === false })
  if (matchesLoading) {
    return null
  }

  return (
    <Card data-testid="matchingJobsWidget">
      {matches?.length === 0 || matchingConsent === false || matchError ? (
        <NoMatches />
      ) : (
        <Matches />
      )}
    </Card>
  )
}
