import React from 'react'
import {
  Button,
  CardActions,
  CardContent,
  List,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import { useGetMatchingJobsQuery } from 'api'

import { Match } from './components'

const Matches = () => {
  const content = useEpiContent<Content>()
  const { data: matches } = useGetMatchingJobsQuery()
  return (
    <>
      <CardContent data-testid="matchingJobs">
        <Typography variant="h6" component={'h2'}>
          {content?.heading}
        </Typography>
        <List>
          {matches?.map((booking, index) => (
            <Match
              isLast={index === matches.length - 1}
              item={booking}
              key={booking.id}
            />
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          data-testid="matchingJobsLink"
          href={content?.linkUrl}
          size="small"
          variant="text"
        >
          {content?.linkText}
        </Button>
      </CardActions>
    </>
  )
}

export default Matches
