import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import { GetMatchingJobsResponse, Match } from 'types'
import { getConfig } from 'utils'

const { API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getMatchingJobs: builder.query<Match[], void>({
      query: () =>
        `/jobs/search/matches?orderBy=applicationdeadline+asc&page=1&pageSize=3`,
      transformResponse: (response: GetMatchingJobsResponse) =>
        response.jobs ?? [],
    }),
  }),
})

export const { useGetMatchingJobsQuery } = api

export default api
