import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { date } from '@trr/frontend-datetime'
import {
  Box,
  CardActionArea,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Content, Match as MatchInterface } from 'types'
import { getEmployerOrRecruiter, getPrioritizedLocation } from 'utils'

const Match = ({ item, isLast }: { item: MatchInterface; isLast: boolean }) => {
  const content = useEpiContent<Content>()
  return (
    <CardActionArea
      data-testid="matchItem"
      href={`${content?.jobbPageLinkBase}${item.id}`}
    >
      <ListItem divider={!isLast} alignItems="flex-start">
        <ListItemText
          data-testid="matchTextBox"
          primaryTypographyProps={{
            component: 'h6',
            variant: 'subtitle1',
          }}
          primary={item.title}
          secondaryTypographyProps={{
            component: 'div',
          }}
          secondary={
            <>
              <Stack
                direction="row"
                divider={
                  <Box fontSize={13} color="neutral.main">
                    ●
                  </Box>
                }
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {getEmployerOrRecruiter(item)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {getPrioritizedLocation(item)}
                </Typography>
              </Stack>
              {item?.applicationDeadline && (
                <Typography variant="caption" fontSize={14}>
                  {`Sista ansökningsdag ${date.format(
                    item.applicationDeadline
                  )}`}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
    </CardActionArea>
  )
}

export default Match
